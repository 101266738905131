import api from "..";
export const getScripts = async () => {
  try {
    const { status, data: response } = await api.get(`ScriptsHeader/Get`);

    return {
      status,
      data: response.data["Scripts"],
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R612S");
  }
};
