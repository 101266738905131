import { Box, Button, Divider, Skeleton, Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../../hooks/AuthContext";
import { GetListaCartaoByIdCliente } from "../../../api/FormaPagamento";
import { GETCARTAOCLIENTE } from "../../../util/typesReactQuery";
import { useState } from "react";
import useAlert from "../../../hooks/useAlert";
import useStyle from "../style";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
const ListaCartoes = ({ onCardSelect, closeModal = null }) => {
  const [listaCartao, setListaCartao] = useState([]);
  const { showAlertError } = useAlert();
  const { user } = useAuth();
  const css = useStyle();

  const { isLoading } = useQuery({
    queryKey: [GETCARTAOCLIENTE, user.id],
    queryFn: () =>
      GetListaCartaoByIdCliente({ idCliente: user.id, ativos: true }),
    onSuccess: (resp) => {
      if (resp?.errorMessage) {
        showAlertError(`Ops Algo deu errado, ${resp.errorMessage} - RSP8D`);
        return;
      }
      setListaCartao(resp?.data ?? []);
    },
    onError: (error) => {
      showAlertError(`Ops Algo deu errado, ${error.message} - RXBGN`);
    },
  });

  return (
    <div>
      {closeModal && (
        <Typography
          variant="body2"
          color="primary"
          style={{
            cursor: "pointer",
            marginBottom: 12,
            alignItems: "center",
            display: "flex",
          }}
          onClick={() => closeModal()}
        >
          <ArrowBackIosOutlinedIcon sx={{ mr: 1 }} fontSize="12" /> Outra forma
          de pagamento
        </Typography>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          sx={{ mb: 1, mt: 1, color: "black" }}
          variant="h6"
          gutterBottom
        >
          Escolher cartão de Crédito
        </Typography>
      </div>
      {isLoading ? (
        <>
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton animation="wave" key={index} height={60} />
          ))}
        </>
      ) : (
        <>
          {listaCartao.map((card) => (
            <Box
              key={card.id}
              sx={{
                mb: 3,
                mt: 3,
                display: "flex",
                alignItems: "center",
                gap: ".3rem",
                cursor: "pointer",
                background: "#eee",
                padding: 2,
              }}
              onClick={() => onCardSelect(card)} // Seleciona o cartão ao clicar no Box
            >
              {/* Espacamento entre os cartões */}
              <Form.Check
                className={css.formCheck}
                type="radio"
                name="savedCard"
                id={card.id}
                onClick={(e) => e.stopPropagation()} // Previne a propagação do clique para o Box
                onChange={() => onCardSelect(card)} // Também dispara a seleção ao clicar no rádio
              />
              <Typography variant="subtitle" sx={{ ml: 2 }}>
                {` ${card.bandeira} **** ${card?.numero?.slice(-4)}`}
              </Typography>
              <Divider />
            </Box>
          ))}

          <Box
            style={{
              marginTop: 20,
              width: "100%",
              padding: 15,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              background: "#eee",
              textAlign: "center",
              cursor: "pointer",
            }}
            variant="contained"
            onClick={() => onCardSelect(null)}
          >
            <CreditCardIcon
              sx={{
                mr: 2,
                color: "#007bff",
                background: "#fff",
                padding: "6px",
                borderRadius: 50,
                fontSize: 30,
              }}
            />{" "}
            Novo Cartão de crédito
          </Box>
        </>
      )}
    </div>
  );
};

export default ListaCartoes;
