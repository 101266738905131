import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  Grid: {
    padding: "0.5rem",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
      display: "flex",
    },

  },
  Input: {
    marginBottom: 15,
  },
  Button: {
    display: 'flex',
    width:'100%',
    marginTop: 10,
    textAlign:'center'
  },
  ButtonVoltar: {
    marginBottom: 15,
  },
  parcelalabel: {
    fontWeight: 'bold !important ',
    fontSize: '17px !important',
    paddingLeft: '10',
  },
  total: {
    opacity: 0.7,
    fontSize: '15px !important',
  },
  parcela: {
    paddingLeft: 10,
  },

  formCheck: {
    display: 'flex',
    alignItems: 'center !important',
    "& .form-check-label": {
      margin: "0 !important",
      marginLeft: ".5rem !important",
      marginTop: ".2rem !important",
    }
  }
}));

export default useStyles;
