const TiposLiquidacao = {
   ContaCorrente: 1,
   Ted: 2,
   Doc: 3,
   Especie: 4,
   CartaoCredito: 7,
   Pix: 9,
   DadosBancarios: 10,
}

export default TiposLiquidacao;