import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import { brlFormatterNotConvert } from "../../../util/CalculosVitrine";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";
import InputAdornment from "@mui/material/InputAdornment";

export default function CampoNumber({
  label,
  value,
  fullWidth,
  size,
  isPercent = false,
  key = null,
  enable = false,
  sx,
  name,
  onChange,
  isLoading,
  currencyFormat = "BRL",
  maximoDigitos = 999,
  variant = "outlined",
  required = false,
  isMonetario = true,
}) {
  const classes = useStyles();
  return (
    <>
      {isLoading && (
        <Skeleton sx={{}} variant="rectangular" height={40} animation={false} />
      )}
      {!isLoading && (
        <FormControl fullWidth={fullWidth} size="medium" variant="outlined">
          <NumberFormat
            key={key}
            value={value}
            name={name}
            disabled={!enable}
            format={(valor) =>
              isMonetario || isPercent
                ? brlFormatterNotConvert(
                    valor,
                    currencyFormat,
                    enable,
                    isPercent
                  )
                : valor
            }
            displayType="text"
            sx={sx}
            maxLength={{ maxLength: maximoDigitos > 0 ? maximoDigitos : 20 }}
            renderText={(valor, props) => (
              <>
                <TextField
                  spellCheck="false"
                  {...props}
                  size={size}
                  type="text"
                  InputProps={{
                    endAdornment: isPercent ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : null,
                  }}
                  variant={variant}
                  required={required}
                  value={valor}
                  label={label}
                  onChange={(e) => {
                    let campoValue = "";
                    if (!Number(e.target.value)) {
                      campoValue = e.target.value
                        .replace("R$", "")
                        .replaceAll(".", "")
                        .replace(",", "");
                    } else {
                      campoValue = e.target.value;
                    }
                    if (isMonetario || isPercent)
                      campoValue = parseFloat(campoValue / 100).toFixed(2);

                    onChange(campoValue, name);
                  }}
                />
              </>
            )}
          ></NumberFormat>
        </FormControl>
      )}
    </>
  );
}
