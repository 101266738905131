import { useState } from "react";
import { Typography, Grid, Box, Skeleton } from "@mui/material";
import { useQuery } from "react-query";
import {
  ConfiguraFormaPagamentoCartao,
  GetParcelasCartao,
} from "../../../api/FormaPagamento";
import useAlert from "../../../hooks/useAlert";
import { formatMoneyInStringForm } from "../../../util/helpers";
import useStyles from "../style";
import { useAuth } from "../../../hooks/AuthContext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ParcelasSelector = ({
  valorPedido,
  onChangeListarCartao,
  onSelectParcela,
  cardSelected,
  idTipoPedido,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [dataParcelas, setDataParcelas] = useState(null);
  const { showAlertError } = useAlert();
  const { user } = useAuth();

  useQuery({
    queryKey: [],
    refetchOnWindowFocus: false,
    queryFn: () => GetParcelasCartao(user.id),
    onSuccess: (resp) => {
      if (resp?.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${resp.errorMessage} - RZVTN `
        );
        return;
      }
      setDataParcelas(resp.data);
    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${error?.message} - REMSE `
      );
    },
    onSettled: () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    },
  });

  const configuraFormaPagamentoCartao = async (parcela) => {
    const response = await ConfiguraFormaPagamentoCartao({
      quantidadeParcelas: parcela.numeroParcela,
      idCartaoPagamento: cardSelected.id,
      idCliente: user.id,
      idTipoPedido: idTipoPedido,
    });

    if (response && response.errorMessage !== null) {
      throw new Error(`Ops, algo deu errado: ${response.errorMessage} - RW6GS`);
    }
  };

  const handleParcelaClick = async (event, parcela) => {
    try {
      setIsLoading(true);
      await configuraFormaPagamentoCartao(parcela);
      // setIsLoading(false);

      // Verifica se onSelectParcela foi passado e chama a função com o evento e o ID da parcela
      if (onSelectParcela) {
        onSelectParcela(event, parcela.numeroParcela);
      }
    } catch (error) {
      showAlertError(error.message + " RDF78");
      setIsLoading(false);
    }
  };

  return (
    <div style={{ borderRadius: "8px", maxWidth: "500px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          background: "#fafafa",
          alignItems: "center",
          padding: 12,
          borderRadius: 4,
        }}
      >
        <Box>
          <Typography variant="subtitle">
            {cardSelected?.bandeira} **** {cardSelected?.numero.slice(-4)}
          </Typography>
        </Box>
        {isLoading ? (
          <Skeleton animation="wave" width={100} height={20} />
        ) : (
          <Typography
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={onChangeListarCartao}
          >
            Escolher outro
          </Typography>
        )}
      </div>
      {!isLoading ? (
        <Typography
          style={{
            fontWeight: "light",
            opacity: 0.7,
            textAlign: "right",
          }}
          onClick={onChangeListarCartao}
        >
          Valor atual do pedido R${" "}
          {formatMoneyInStringForm(dataParcelas?.valorBaseCalculo)}
        </Typography>
      ) : (
        <Skeleton
          sx={{ display: "block", marginLeft: "auto" }}
          animation="wave"
          height={20}
          width={150}
        />
      )}
      {isLoading
        ? Array.from({ length: 6 }, (_, index) => (
            <Skeleton animation="wave" key={index} height={60} />
          ))
        : dataParcelas?.parcelas.map((parcela) => (
            <Grid
              container
              key={parcela.numeroParcela}
              alignItems="center"
              sx={{
                background: "#fff",
                border: "1px solid #eee",
                mb: 1,
                mt: 1,
                p: 3,
                borderRadius: 1,
                cursor: "pointer",
                "&:hover": { background: "#f0f0f0" },
              }}
              onClick={(event) => handleParcelaClick(event, parcela)}
            >
              <Grid item xs={8}>
                <Typography sx={{ fontSize: 15 }}>
                  {parcela.numeroParcela}x R${" "}
                  {formatMoneyInStringForm(parcela.valorParcela ?? 0.0)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ opacity: 0.7, textAlign: "right" }}>
                  R$ {formatMoneyInStringForm(parcela.valorTotalComJuros)}
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: "right" }}>
                <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#444" }} />
              </Grid>
            </Grid>
          ))}
    </div>
  );
};

export default ParcelasSelector;
