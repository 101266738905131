import api from "../";
import { configurationVariables } from "../../environment";
import TiposPedido from "../../util/typesTipoPedido";
import TiposOrigem from "../../util/typesTipoOrigem";

const idOrigem = 3;
const idHotsite = parseInt(configurationVariables.IdHotsite);

// //! Pega todas as formas de pagamento
// export const GetFormaPagamentoComercial = async (idTipoOperacao, pass) => {

//    try {
//       const { data: response, status } = await api.get(`/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoOperacao}`)

//       return {
//          status,
//          errorMessage: response.errorMessage,
//          data: response.data,
//       }
//    } catch (error) {
//       throw new Error(error + "R3BY5");
//    }
// }

// //! Pega todas as formas de pagamento
// export const GetFormaPagamentoTurismo = async (idTipoOperacao, idPraca) => {

//    try {
//       const { data: response, status } = await api.get(`/FormaPagamento/GetByIdTipoPedidoIdPraca?idTipoPedido=${idTipoOperacao}&idPraca=${idPraca}`)

//       return {
//          status,
//          errorMessage: response.errorMessage,
//          data: response.data,
//       }
//    } catch (error) {
//       throw new Error(error + "R3BY5");
//    }
// }

export const GetFormaPagamento = async (idTipoPedido, idPraca) => {
  try {
    let response = { data: "", errorMessage: "", status: 0 };

    switch (idTipoPedido) {
      case TiposPedido.Recebimento:
        let dataRecebimento = await api.get(
          `/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoPedido}`
        );

        response.data = dataRecebimento.data.data;
        response.status = dataRecebimento.status;
        response.errorMessage = dataRecebimento.data.errorMessage;

        break;

      case TiposPedido.Remessa:
        let dataRemessa = await api.get(
          `/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoPedido}`
        );

        response.data = dataRemessa.data.data;
        response.status = dataRemessa.status;
        response.errorMessage = dataRemessa.data.errorMessage;

        break;

      case TiposPedido.SeguroViagem:
        let dataSeguro = await api.get(
          `/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoPedido}`
        );

        response.data = dataSeguro.data.data;
        response.status = dataSeguro.status;
        response.errorMessage = dataSeguro.data.errorMessage;

        break;

      case TiposPedido.VendaChipInternacional:
        let dataChip = await api.get(
          `/FormaPagamento/GetByIdTipoPedido?idTipoPedido=${idTipoPedido}`
        );

        response.data = dataChip.data.data;
        response.status = dataChip.status;
        response.errorMessage = dataChip.data.errorMessage;

        break;

      default:
        if (idPraca <= 0) {
          throw new Error("A praça selecionada esta invalida");
        }

        let { data, status } = await api.get(
          `/FormaPagamento/GetByIdTipoPedidoIdPraca?idTipoPedido=${idTipoPedido}&idPraca=${idPraca}`
        );

        response.data = data.data;
        response.status = status;
        response.errorMessage = data.errorMessage;
        break;
    }

    return {
      status: response.status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R3BY5");
  }
};

//! Adiciona a forma de pagamento selecionada no carrinho Temp
export const AddCarrinhoTempConfigurePagamento = async (formData) => {
  try {
    const { data: response } = await api.post(
      `/CarrinhoTemp/ConfiguraPagamento?idCliente=${formData?.userID}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
      {
        idFormaPagamento: formData?.idFormaPagamento,
        descricao: formData?.descricao,
      }
    );

    return response;
  } catch (error) {
    throw new Error(error + " RG77R");
  }
};

//! Pega a forma de pagamento com base em seu ID
export const GetFormaPagamentoByID = async (idFormaPagamento) => {
  try {
    const { data: response, status } = await api.get(
      `/FormaPagamento/GetById?id=${idFormaPagamento}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "RP64E");
  }
};

//! Pega a forma de pagamento com base em seu ID
export const GetCanalBancarioExteriorByIDMoeda = async (idMoeda) => {
  try {
    const { data: response, status } = await api.get(
      `/FormaPagamento/GetCanalBancarioExteriorByIDMoeda?idMoeda=${idMoeda}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R78E8");
  }
};

export const EnviarEmailRemetenteExterior = async (data) => {
  try {
    const response = await api.post(
      `/FormaPagamento/EnviaEmailCanalBancarioExteriorByIDMoeda?idMoeda=${data?.idMoeda}&email=${data?.email}`
    );
  } catch (error) {
    throw new Error(error + " RABC6");
  }
};

export const GetParcelasCartao = async (idCliente) => {
  try {
    const { data: response } = await api.get(
      `/CarrinhoGeral/GetParcelas?idCliente=${idCliente}&idOrigem=${TiposOrigem.lojaCambioWeb}&idHotsite=${idHotsite}`
    );

    return {
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " R9X4F");
  }
};

export const ConfiguraFormaPagamentoCartao = async (dadosFormaPagamento) => {
  try {
    let response = { data: "", errorMessage: "", status: 0 };
    
    switch (dadosFormaPagamento.idTipoPedido) {
      case TiposPedido.Remessa:
        // const { data: response } = await api.post(
        //   `/CarrinhoTurismo/ConfiguraDadosCartaoPagamento?idCliente=${dadosFormaPagamento?.idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&quantidadeParcelas=${dadosFormaPagamento?.quantidadeParcelas}&idCartaoPagamento=${dadosFormaPagamento?.idCartaoPagamento}`
        // );

        let dataRemessa = await api.post(
          `/CarrinhoRemessa/ConfiguraDadosCartaoPagamento?idCliente=${dadosFormaPagamento?.idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&quantidadeParcelas=${dadosFormaPagamento?.quantidadeParcelas}&idCartaoPagamento=${dadosFormaPagamento?.idCartaoPagamento}`
        );
        
        console.log('dataRemessa', dataRemessa);

        response.data = dataRemessa.data.data;
        response.status = dataRemessa.status;
        response.errorMessage = dataRemessa.data.errorMessage;
        break;
      case TiposPedido.Recebimento:
        break;
      case TiposPedido.SeguroViagem:
        break;
      case TiposPedido.VendaChipInternacional:
        break;
      case TiposPedido.VendaEspecie:
        let dataVendaEspecie = await api.post(
          `/CarrinhoTurismo/ConfiguraDadosCartaoPagamento?idCliente=${dadosFormaPagamento?.idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&quantidadeParcelas=${dadosFormaPagamento?.quantidadeParcelas}&idCartaoPagamento=${dadosFormaPagamento?.idCartaoPagamento}`
        );
        response.data = dataVendaEspecie.data.data;
        response.status = dataVendaEspecie.status;
        response.errorMessage = dataVendaEspecie.data.errorMessage;
        break;
      case TiposPedido.VendaOuro:
        let dataVendaOuro = await api.post(
          `/CarrinhoOuro/ConfiguraDadosCartaoPagamento?idCliente=${dadosFormaPagamento?.idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&quantidadeParcelas=${dadosFormaPagamento?.quantidadeParcelas}&idCartaoPagamento=${dadosFormaPagamento?.idCartaoPagamento}`
        );
        response.data = dataVendaOuro.data.data;
        response.status = dataVendaOuro.status;
        response.errorMessage = dataVendaOuro.data.errorMessage;
        break;
        break;
    }

    return {
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " RH8GV");
  }
};

export const SaveCartao = async (dadosCartao) => {
  try {
    const response = await api.post(`/CartaoPagamento/Save`, {
      idCliente: dadosCartao.idCliente,
      numero: dadosCartao.numero,
      bandeira: dadosCartao.bandeira,
      nomeTitular: dadosCartao.nomeTitular,
      hashCartao: dadosCartao.hashCartao,
    });

    return response;
  } catch (error) {
    throw new Error(error + " RYB72");
  }
};

export const GetListaCartaoByIdCliente = async (objBusca) => {
  try {
    const { data: response } = await api.get(
      `/CartaoPagamento/GetByIdCliente?idCliente=${objBusca?.idCliente}&apenasAtivos=${objBusca?.ativos}`
    );

    return {
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " R9X4F");
  }
};
