import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-credit-cards/es/styles-compiled.css";
import { Typography } from "@mui/material";
import "./CartaoCss.css";
import ParcelasSelector from "./Parcelas/parcelas";
import ListaCartoes from "./ListaCartoes";
import usePedido from "../../hooks/usePedido";
import CadastrarCartao from "./CadastrarCartao";

export const Telas = {
  cadastrar: 1,
  listar: 2,
  parcelas: 3,
};

const CreditCardForm = ({ closeModal = null, onSelectCard, idTipoPedido }) => {
  const [showAdicionarCartao, setShowAdiconarCartao] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showParcelas, setShowParcelas] = useState(false);
  const [showListaCartao, setShowListaCartao] = useState(true);
  const { itensPedido } = usePedido();

  const handlerChangeTelas = (tela) => {
    switch (tela) {
      case Telas.cadastrar:
        setShowAdiconarCartao(true);
        setShowListaCartao(false);
        setShowParcelas(false);
        break;
      case Telas.listar:
        setShowListaCartao(true);
        setShowAdiconarCartao(false);
        setShowParcelas(false);
        break;
      case Telas.parcelas:
        setShowParcelas(true);
        setShowListaCartao(false);
        setShowAdiconarCartao(false);
        break;
      default:
        setShowListaCartao(true);
        setShowParcelas(false);
        setShowAdiconarCartao(false);
        break;
    }
  };

  const handleCardSelect = (card) => {
    if (card?.id) {
      setSelectedCard(() => card);
      handlerChangeTelas(Telas.parcelas);
    } else {
      handlerChangeTelas(Telas.cadastrar);
    }
  };

  const handlerSelectParcela = (event) => {
    console.log("chegou aqui", event);
    onSelectCard(event);
  };

  return (
    <div className="container">
      <div className="box justify-content-center align-items-center">
        <div className="formDiv">
          {/* Exibir cartões cadastrados antes de tudo */}
          {showListaCartao ? (
            <ListaCartoes
              closeModal={closeModal}
              onCardSelect={handleCardSelect}
            />
          ) : !showParcelas ? (
            <CadastrarCartao
              setShowListaCartao={() => handlerChangeTelas(Telas.listar)}
            />
          ) : (
            <div>
              {/* <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Selecione a quantidade de parcelas
              </Typography> */}
              <ParcelasSelector
                cardSelected={selectedCard}
                valorPedido={itensPedido?.valorTotalMoedaNacional ?? 0}
                onSelectParcela={handlerSelectParcela}
                onChangeListarCartao={() => handlerChangeTelas(Telas.listar)}
                idTipoPedido={idTipoPedido}
              ></ParcelasSelector>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
