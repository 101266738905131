import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import useStyles from "../style";
import { Box, Button, Divider, Skeleton, Typography } from "@mui/material";
import useForm from "../Validacao/useForm";
import "../CartaoCss.css";
import { useMutation } from "react-query";
import { SaveCartao } from "../../../api/FormaPagamento";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert";
import { configurationVariables } from "../../../environment";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import InputMask from "react-input-mask";

const CadastrarCartao = ({ setShowListaCartao }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { showAlertError } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleChange,
    handleFocus,
    handleSubmit,
    setIsValidCampos,
    isValidCampos,
    values: cartao,
    errors,
  } = useForm();

  useEffect(() => {
    let script = document.createElement("script");
    script.src = "https://js.iugu.com/v2";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const mutationSaveCard = useMutation({
    mutationFn: SaveCartao,
    onSuccess: (resp) => {
      if (!resp || resp?.errorMessage) {
        showAlertError(
          `Ops Algo deu errado, ${
            resp?.errorMessage ?? " não recebos resposta"
          } - RGF1C`
        );
        return;
      }
      setShowListaCartao();
    },
    onError: (error) => {
      showAlertError(`Ops Algo deu errado, ${error?.message} - RYZ0C`);
    },
    onSettled: () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    },
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    handleSubmit(event);

    handlerCadastrarCartao(event);
  };

  const validaCartao = () => {
    let isValidNumber = window.Iugu.utils.validateCreditCardNumber(
      cartao.numero
    );
    let bandeira = window.Iugu.utils.getBrandByCreditCardNumber(cartao.numero);
    let isValidCVV = window.Iugu.utils.validateCVV(cartao.cvc, bandeira);
    let isValidData = window.Iugu.utils.validateExpirationString(
      cartao.dataExpiracao
    );

    if (!isValidNumber) {
      setIsValidCampos((state) => ({ ...state, cNumber: false }));
      showAlertError("Ops, o numero do cartão informado é invalido");
      return false;
    } else {
      setIsValidCampos((state) => ({ ...state, cNumber: true }));
    }

    if (!isValidData) {
      setIsValidCampos((state) => ({ ...state, cData: false }));
      showAlertError("Ops, a data de expiração informada é invalido");
      return false;
    } else {
      setIsValidCampos((state) => ({ ...state, cData: true }));
    }

    if (!isValidCVV) {
      setIsValidCampos((state) => ({ ...state, cCvv: false }));
      showAlertError(
        "Ops, o codigo de segurança informado não corresponde ao cartão"
      );
      return false;
    } else {
      setIsValidCampos((state) => ({ ...state, cCvv: true }));
    }

    return true;
  };

  const saveCard = async (contentData) => {
    let newCard = {
      idCliente: user.id,
      numero: cartao.numero.slice(-4),
      bandeira: contentData.extra_info.brand,
      nomeTitular: cartao.nomeTitular,
      hashCartao: contentData.id,
    };

    mutationSaveCard.mutate(newCard);
  };

  const checkErrors = (contentData) => {
    let textError = Object.keys(contentData.errors).reduce(
      (prevValue, nextValue, index) => {
        let value = "";
        if (nextValue === "number") value = "numero do cartão";

        if (nextValue === "verification_value") value = "codigo de segurança ";

        if (nextValue === "expiration") value = "data de expiração";

        if (nextValue === "last_name") value = "nome do titular";

        return ` ${value}, ${prevValue}`;
      },
      ""
    );
    console.log(contentData);
    showAlertError(`Ops, algo deu errado nos seguintes campos: ${textError} `);

    setIsLoading(() => false);
  };

  const handlerCadastrarCartao = async (event) => {
    event.preventDefault();
    if (window.Iugu) {
      let [mes, ano] = cartao.dataExpiracao.split("/");
      let [primeiroNome, ...sobrenome] = cartao.nomeTitular.split(" ");

      window.Iugu.setAccountID(configurationVariables.accountId);
      window.Iugu.setTestMode(true);

      let isValid = validaCartao();

      if (isValid) {
        setIsLoading(() => true);
        let creditCard = window.Iugu.CreditCard(
          cartao.numero,
          mes,
          ano,
          primeiroNome,
          sobrenome.join(" "),
          cartao.cvc
        );
        window.Iugu.createPaymentToken(creditCard, function (response) {
          if (response?.errors) {
            checkErrors(response);
          } else {
            saveCard(response);
          }
        });
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Skeleton variant="rectangular" height={180} />
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} variant="rectangular" height={40} />
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            color="primary"
            style={{
              cursor: "pointer",
              marginBottom: 15,
              alignItems: "center",
              display: "flex",
            }}
            onClick={setShowListaCartao}
          >
            <ArrowBackIosOutlinedIcon sx={{ mr: 1 }} fontSize="12" /> Escolher
            outro cartão
          </Typography>

          <div className="creditCard">
            <Cards
              cvc={"*".repeat(cartao.cvc.length)}
              expiry={cartao.dataExpiracao}
              focused={cartao.focus}
              name={cartao.nomeTitular}
              number={cartao.numero}
            />
          </div>

          <Form onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Control
                required
                className={classes.Input}
                id="cardNumber"
                data-testid="cardNumber"
                name="numero"
                maxLength={16}
                placeholder="Número do cartão"
                value={cartao.numero}
                onChange={handleChange}
                onFocus={handleFocus}
                isValid={isValidCampos.cNumber}
              />
            </Form.Group>

            <Form.Group>
              <Form.Control
                required
                type="text"
                id="cardName"
                data-testid="cardName"
                name="nomeTitular"
                placeholder="Nome Completo"
                value={cartao.nomeTitular}
                onChange={handleChange}
                onFocus={handleFocus}
                isValid={isValidCampos.cNome}
              />
              <Typography sx={{ mb: 2, fontSize: 12, ml: 1, opacity: 0.7 }}>
                Conforme aparece no cartão
              </Typography>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group>
                  <InputMask
                    mask="99/99"
                    value={cartao.dataExpiracao}
                    onChange={handleChange}
                    onFocus={handleFocus}
                  >
                    {() => (
                      <Form.Control
                        required
                        type="text"
                        id="cardExpiration"
                        data-testid="cardExpiration"
                        name="dataExpiracao"
                        placeholder="Validade"
                        isValid={isValidCampos.cData}
                      />
                    )}
                  </InputMask>
                  <Typography sx={{ mb: 2, fontSize: 12, ml: 1, opacity: 0.7 }}>
                    Mês/Ano
                  </Typography>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Control
                    required
                    id="cardSecurityCode"
                    data-testid="cardSecurityCode"
                    name="cvc"
                    placeholder="Código de Segurança"
                    value={cartao.cvc}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    isValid={isValidCampos.cCvv}
                  />
                  <Typography sx={{ mb: 2, fontSize: 12, ml: 1, opacity: 0.7 }}>
                    CVC
                  </Typography>
                </Form.Group>
              </Col>
            </Row>

            <Button
              className={classes.Button}
              variant="contained"
              data-testid="validateButton"
              id="validateButton"
              // onClick={handlerCadastrarCartao} // Chama handleContinue
              type="submit"
              disabled={
                !cartao.numero ||
                !cartao.nomeTitular ||
                !cartao.dataExpiracao ||
                !cartao.cvc
              } // Desabilita se os campos obrigatórios não estão preenchidos
            >
              Continuar
            </Button>
          </Form>
        </>
      )}
    </div>
  );
};

export default CadastrarCartao;
