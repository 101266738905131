import { useState, useEffect, Fragment } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { getDadosBasicos, getDadosBasicosV2 } from "../../../api/Cadastro";
import CampoTexto from "../../Campos/CampoTexto";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import CampoCheck from "../../Campos/CampoCheck";
import CampoNumber from "../../Campos/CampoNumber";
import CampoData from "../../Campos/CampoData";
import useStyle from "./styles";
import { Grid, Skeleton, Box } from "@mui/material";
import Seletor from "../../Seletor";
import Subtitulo from "../../Textos/Subtitulo";
import CustomGrid from "../../GridCadastroCustom";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import Indicações from "./Indicacoes";

export default function DadosBasicos({
  idCliente,
  setDada,
  isLoading,
  idPerfilLimiteOperacional,
  setNome,
  setDisabledButton = null,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});
  const [idEstadoCivil, setIdEstadoCivil] = useState(0);
  const [idNacionalidade, setIdNacionalidade] = useState(0);
  const [possuiImovelEstrangeiro, setPossuiImovelEstrangeiro] = useState(false);
  const [possuiImovel, setPossuiImovel] = useState(false);

  const init = async () => {
    const response = await getDadosBasicosV2(
      dadosForm,
      idCliente,
      idPerfilLimiteOperacional,
      idEstadoCivil,
      idNacionalidade,
      possuiImovelEstrangeiro,
      possuiImovel
    );

    setDadosForm(response);
    setDada(response);
  };

  useEffect(() => {
    //Força o reloading dos campos
    setDadosForm(() => ({
      ...dadosForm,
      dadosPessoaFisica: null,
      dadosPessoaJuridica: null,
      dadosPessoaEstrangeira: null,
    }));
    init();
  }, [
    idPerfilLimiteOperacional,
    idEstadoCivil,
    idNacionalidade,
    possuiImovelEstrangeiro,
    possuiImovel,
  ]);

  const handlerChangePhoneInternatuinal = (prop, value) => {
    console.log("telefone internacional value", value);

    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === prop) return { ...c, ["valor"]: value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handlerChangeValue = (e) => {
    if (e.target.name === "Nome ") {
      setNome(e.target.value);
    }

    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handlerChangeSelect = (proName, selectedValue) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === proName)
        return { ...c, ["selecedID"]: selectedValue };
      else return c;
    });

    if (proName === "EstadoCivil") setIdEstadoCivil(selectedValue);

    if (proName === "IDNacionalidade") setIdNacionalidade(selectedValue);

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handlerResponsavelChangeValue = (e) => {
    const newValue = dadosForm.camposResponsavelLegal.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["camposResponsavelLegal"]: newValue });
    setDada({ ...dadosForm, ["camposResponsavelLegal"]: newValue });
  };

  const handleChangeCheck = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.checked };
      else return c;
    });

    if (e.target.name === "PossuiImovelEstrangeiro")
      setPossuiImovelEstrangeiro(e.target.checked);
    else if (e.target.name === "PossuiImovel")
      setPossuiImovel(e.target.checked);

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handlerChangeValueNumero = (value, propriedade) => {
    const updatedObjects = configureNewArrayCampos(
      dadosForm.campos,
      propriedade,
      value,
      true
    );
    setDadosForm({ ...dadosForm, ["campos"]: updatedObjects });
    setDada({ ...dadosForm, ["campos"]: updatedObjects });
  };

  const configureNewArrayCampos = (
    array,
    propriedade,
    value,
    isNumber = false
  ) => {
    let newCampos = [];
    if (isNumber) {
      newCampos = array.map((obj) => {
        if (obj.propriedade === propriedade) {
          return {
            ...obj,
            valor: value,
          };
        }
        return obj;
      });

      return newCampos;
    }

    newCampos = array.map((c) => {
      if (c.propriedade === propriedade) return { ...c, ["valor"]: value };
      else return c;
    });

    return newCampos;
  };

  const formatDateValueInput = (value) => {
    if (value.includes("T")) {
      return value.split("T")[0];
    } else {
      return value;
    }
  };

  return (
    <>
      <div className={classes.box}>
        {dadosForm && (
          <>
            {dadosForm.enableIndicacoes && (
              <Indicações
                classes={classes}
                dadosForm={dadosForm}
                setForm={setDadosForm}
                setData={setDada}
                isLoading={isLoading}
                setDisabledButton={setDisabledButton}
                idPerfilLimiteOperacional={idPerfilLimiteOperacional}
              />
            )}
            {isLoading ? (
              <Skeleton animation="wave" width={"10%"} height={30} />
            ) : (
              <Subtitulo
                sx={{
                  fontWeight: "500",
                  clear: "both",
                  mt: 3,
                  mb: 3,
                  fontSize: 15,
                  color: "#000",
                }}
                label={dadosForm.tituloCampos}
              />
            )}
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 1, md: 2 }}
              sx={{ mb: 2 }}
            >
              {dadosForm &&
                dadosForm.campos &&
                dadosForm.campos.map((c, i, array) => (
                  <Fragment key={i}>
                    <CustomGrid index={i} array={array} md={6}>
                      {c.tipoControle === "Texto" &&
                        c.tipoMascara.length <= 0 &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CampoTexto
                              key={i}
                              enable={
                                c.propriedade === "EmailPrincipal"
                                  ? false
                                  : c.enable
                              }
                              required={c.obrigatorio}
                              variant="filled"
                              size="medium"
                              type="text"
                              fullWidth={true}
                              defaultValue=" "
                              label={c.label}
                              name={c.propriedade}
                              value={c.valor}
                              handlerChange={handlerChangeValue}
                              className={classes.campo}
                              autoFocus
                              maximoDigitos={c.maxLength}
                            />
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoControle === "Texto" &&
                        c.tipoMascara === null &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CampoTexto
                              key={i}
                              enable={
                                c.propriedade === "EmailPrincipal"
                                  ? false
                                  : c.enable
                              }
                              required={c.obrigatorio}
                              variant="filled"
                              size="medium"
                              type="text"
                              fullWidth={true}
                              defaultValue=" "
                              label={c.label}
                              name={c.propriedade}
                              value={c.valor}
                              handlerChange={handlerChangeValue}
                              className={classes.campo}
                              autoFocus
                              maximoDigitos={c.maxLength}
                            />
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoControle === "Numero" &&
                        c.tipoMascara.length <= 0 &&
                        (isLoading ? (
                          <Skeleton animation="wave" height={60} />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CampoNumber
                              enable={c.enable}
                              variant="outlined"
                              size="medium"
                              fullWidth
                              label={c.label}
                              name={c.propriedade}
                              value={c.valor}
                              onChange={handlerChangeValueNumero}
                              className={classes.campo}
                              maximoDigitos={c.maxLength}
                              required={c.obrigatorio}
                            />
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoControle === "Numero" &&
                        c.tipoMascara.length > 0 &&
                        (isLoading ? (
                          <Skeleton animation="wave" height={60} />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CampoNumber
                              enable={c.enable}
                              isMonetario={c.tipoMascara === "Monetaria"}
                              isPercent={
                                c.tipoMascara === "percentual" ||
                                c.propriedade === "PercentualCapital"
                              }
                              variant="outlined"
                              size="medium"
                              fullWidth
                              label={c.label}
                              name={c.propriedade}
                              value={c.valor}
                              onChange={handlerChangeValueNumero}
                              className={classes.campo}
                              maximoDigitos={c.maxLength}
                              required={c.obrigatorio}
                            />
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {(c.tipoControle === "Data" ||
                        c.tipoMascara === "Data") &&
                        (isLoading ? (
                          <Skeleton animation="wave" height={55} />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CampoData
                              key={`cd_${i}`}
                              index={i}
                              enable={c.enable}
                              size="medium"
                              label={c.label}
                              fullWidth={true}
                              name={c.propriedade}
                              value={formatDateValueInput(c.valor)}
                              onChange={handlerChangeValue}
                              className={classes.campo}
                              required={c.obrigatorio}
                            />
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoControle === "Selecao" &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <>
                            <Seletor
                              setSelect={(e) =>
                                handlerChangeSelect(c.propriedade, e)
                              }
                              list={c.items}
                              size="medium"
                              label={c.label}
                              fullWidth={true}
                              value={c.valor}
                            />
                          </>
                        ))}

                      {c.tipoMascara === "Celular" &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <InputMask
                              onChange={handlerChangeValue}
                              mask={"(99) 99999-9999"}
                              value={c.valor}
                              enable={c.enable}
                              maskChar="_"
                            >
                              {() => (
                                <TextField
                                  spellCheck="false"
                                  required={c.obrigatorio}
                                  fullWidth={true}
                                  label={c.label}
                                  maxLength={c.maxLength}
                                  name={c.propriedade}
                                  value={c.valor}
                                  size="medium"
                                  className={classes.campo}
                                  onChange={handlerChangeValue}
                                />
                              )}
                            </InputMask>
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}
                      {c.tipoMascara === "TelefoneFixo" &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <InputMask
                              onChange={handlerChangeValue}
                              mask={"(99) 9999-9999"}
                              value={c.valor}
                              enable={c.enable}
                              maskChar="_"
                            >
                              {() => (
                                <TextField
                                  spellCheck="false"
                                  required={c.obrigatorio}
                                  fullWidth={true}
                                  label={c.label}
                                  maxLength={c.maxLength}
                                  name={c.propriedade}
                                  value={c.valor}
                                  size="medium"
                                  className={classes.campo}
                                  onChange={handlerChangeValue}
                                />
                              )}
                            </InputMask>
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoMascara === "TelefoneInternacional" &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {/* <PhoneInput
                              defaultCountry="br"
                              value={c.valor}
                              onChange={(phone) => handlerChangePhoneInternatuinal(c.propriedade, phone)}
                            /> */}
                            <InputMask
                              onChange={handlerChangeValue}
                              mask={"+999 999 999 999"}
                              value={c.valor}
                              enable={c.enable}
                              maskChar="_"
                            >
                              {() => (
                                <TextField
                                  spellCheck="false"
                                  required={c.obrigatorio}
                                  fullWidth={true}
                                  label={c.label}
                                  maxLength={c.maxLength}
                                  name={c.propriedade}
                                  value={c.valor}
                                  size="medium"
                                  className={classes.campo}
                                  onChange={handlerChangeValue}
                                />
                              )}
                            </InputMask>
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoMascara === "CPF" &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <InputMask
                              onChange={handlerChangeValue}
                              mask={"999.999.999-99"}
                              value={c.valor}
                              disabled={
                                c.propriedade === "NumeroDocumentoIdentificacao"
                                  ? true
                                  : !c.enable
                              }
                              maskChar="_"
                            >
                              {() => (
                                <TextField
                                  spellCheck="false"
                                  required={c.obrigatorio}
                                  fullWidth={true}
                                  label={c.label}
                                  maxLength={c.maxLength}
                                  disabled={
                                    c.propriedade ===
                                    "NumeroDocumentoIdentificacao"
                                      ? true
                                      : !c.enable
                                  }
                                  name={c.propriedade}
                                  value={c.valor}
                                  size="medium"
                                  className={classes.campo}
                                  onChange={handlerChangeValue}
                                />
                              )}
                            </InputMask>
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}
                      {c.tipoMascara === "CNPJ" &&
                        (isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <InputMask
                              onChange={handlerChangeValue}
                              mask={"99.999.999/9999-99"}
                              value={c.valor}
                              disabled={
                                c.propriedade === "NumeroDocumentoIdentificacao"
                                  ? true
                                  : !c.enable
                              }
                              maskChar="_"
                            >
                              {() => (
                                <TextField
                                  spellCheck="false"
                                  required={c.obrigatorio}
                                  fullWidth={true}
                                  label={c.label}
                                  maxLength={c.maxLength}
                                  disabled={
                                    c.propriedade ===
                                    "NumeroDocumentoIdentificacao"
                                      ? true
                                      : !c.enable
                                  }
                                  name={c.propriedade}
                                  value={c.valor}
                                  size="medium"
                                  className={classes.campo}
                                  onChange={handlerChangeValue}
                                />
                              )}
                            </InputMask>
                            {c.informacao && (
                              <Tooltip title={c.informacao}>
                                <IconButton>
                                  <InfoIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        ))}

                      {c.tipoControle === "Check" &&
                        (isLoading ? (
                          <Skeleton animation="wave" height={60} />
                        ) : (
                          <CampoCheck
                            key={`cc_${i}`}
                            index={i}
                            enable={c.enable}
                            variant="filled"
                            size="medium"
                            fullWidth={false}
                            label="Sim"
                            descricao={c.label}
                            name={c.propriedade}
                            value={c.valor}
                            handleChangeCheck={handleChangeCheck}
                            className={classes.campo}
                          />
                        ))}
                    </CustomGrid>
                  </Fragment>
                ))}

              {dadosForm.camposResponsavelLegal &&
                dadosForm.camposResponsavelLegal.length > 0 && (
                  <>
                    {!isLoading && (
                      <Subtitulo
                        sx={{
                          fontWeight: "500",
                          clear: "both",
                          mt: 3,
                          mb: 1,
                          pl: 2,
                          fontSize: 15,
                          color: "#000",
                          width: "100%",
                        }}
                        label="Responsável pelo Cadastro/Representante Legal"
                      />
                    )}
                    {dadosForm.camposResponsavelLegal.map((c, i, array) => (
                      <Fragment key={i}>
                        <CustomGrid index={i} array={array} md={6}>
                          {c.tipoControle === "Texto" &&
                            c.tipoMascara.length <= 0 &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={60}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <CampoTexto
                                  key={i}
                                  enable={
                                    c.propriedade === "EmailPrincipal"
                                      ? false
                                      : c.enable
                                  }
                                  required={c.obrigatorio}
                                  variant="filled"
                                  size="medium"
                                  type="text"
                                  fullWidth={true}
                                  defaultValue=" "
                                  label={c.label}
                                  name={c.propriedade}
                                  value={c.valor}
                                  handlerChange={handlerResponsavelChangeValue}
                                  className={classes.campo}
                                  autoFocus
                                  maximoDigitos={c.maxLength}
                                />
                                {c.informacao && (
                                  <Tooltip title={c.informacao}>
                                    <IconButton>
                                      <InfoIcon
                                        fontSize="small"
                                        color="primary"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            ))}
                        </CustomGrid>
                      </Fragment>
                    ))}
                  </>
                )}
            </Grid>
          </>
        )}
      </div>
    </>
  );
}
