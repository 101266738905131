
import React, { useState } from 'react';

import { Grid } from "@mui/material";
import VerificationInput from "react-verification-input";
import useStyle from './styles.js'
import StepStatus from "../../components/stepsStatus/index.js";
import CreditCardForm from '../../components/CartaoCredito/CreditCard.js';

export default function   () {
 

  return (
    <>
      <h1>
        Pagina de Teste
      </h1>
      <Grid container spacing={2} >
        <StepStatus />
      </Grid>



  <CreditCardForm></CreditCardForm>
    </>
  );
}
