import { useState } from 'react'
import validateInfo from '../Validacao/ValidateInfo';

const useForm = () => {
    const [cartao, setCartao] = useState({
        nomeTitular: '',
        numero: '',
        cardType: '',
        dataExpiracao: '',
        cvc: '',
        focus: ''
    });

    const [isValidCampos, setIsValidCampos] = useState(false);


    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false); // Novo estado para indicar se o formulário é válido

    const handleFocus = (e) => {
        setCartao({
            ...cartao,
            focus: (e.target.name === 'cardSecurityCode') ? 'cvc' : e.target.name
        });
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setCartao({
            ...cartao,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        const validationErrors = validateInfo(cartao); // Validação do formulário
        // setErrors(validationErrors);

        // Verifique se todos os campos foram validados corretamente
        if (validationErrors.cname && validationErrors.cnumber && validationErrors.cexp && validationErrors.ccvv) {
            setIsValid(true); // Define o formulário como válido
        } else {
            setIsValid(false); // Define como inválido se houver algum erro
        }
    };

    return { handleChange, handleFocus, handleSubmit, setIsValidCampos, isValidCampos, values: cartao, errors, isValid }; // Retorna isValid para controle externo
};

export default useForm;
