import valid from "card-validator";

export default function validateInfo(values) {
  let errors = {};
  let creditCard = valid.number(values.numero);

  creditCard.expirationDate = valid.expirationDate(values.dataExpiracao);
  creditCard.cvv = valid.cvv(values.cvc);
  creditCard.cardholderName = valid.cardholderName(values.nomeTitular);
  // creditCard.postalCode = valid.postalCode(values.cardPostalCode);

  errors.show = true;
  errors.variant = "danger";
  errors.message = "An unknown error occured. Please try again later"
  errors.cname = false;
  errors.cnumber = false;
  // errors.ctype = false;
  errors.cexp = false;
  errors.ccvv = false;
  // errors.cpostal = false;

  // if (values.cardPostalCode === null || !values.cardPostalCode.trim()) {
  //   errors.message = "Credit card postal code is not complete";
  // } else if (creditCard.postalCode.isValid) {
  //   errors.cpostal = true;
  // } else {
  //   errors.message = "Credit card postal code is invalid";
  // }

  //Card CVV expiration
  if (values.cvc === null || !values.cvc.trim()) {
    errors.message = "Código de segurança incompleto";
  } else if (creditCard.cvv.isValid) {
    errors.ccvv = true;
  } else {
    errors.message = "Código de segurança inválido";
  }

  //Card Expiration Verification
  if (values.dataExpiracao === null || !values.dataExpiracao.trim()) {
    errors.message = "Data de validade incompleta";
  } else if (creditCard.expirationDate.isValid) {
    errors.cexp = true;
  } else {
    errors.message = "Validade não é válida";
  }

  // //Card Type Verification
  // if (
  //   values.cardType === null ||
  //   !values.cardType.trim() ||
  //   creditCard.card === null
  // ) {
  //   errors.message = "Credit card type is not complete";
  // } else if (
  //   creditCard.card.type &&
  //   creditCard.card.type.toUpperCase() === values.cardType.toUpperCase()
  // ) {
  //   errors.ctype = true;
  // } else {
  //   errors.message = "Credit card type is invalid";
  // }

  //Card Number Verification
  if (values.numero === null || !values.numero.trim()) {
    errors.message = "Número do Cartão de crédito não está completo";
  } else if (creditCard.isValid) {
    errors.cnumber = true;
  } else {
    errors.message = "Número do Cartão de crédito é inválido";
  }

  //Cardholder Name Verification
  if (values.nomeTitular === null || !values.nomeTitular.trim()) {
    errors.message = "Cardholder name is not complete";
  } else if (creditCard.cardholderName.isValid) {
    errors.cname = true;
  } else {
    errors.message = "Nome inválido";
  }

  if (
    // errors.ctype &&
    errors.cname &&
    errors.cnumber &&
    errors.cexp &&
    // errors.cpostal &&
    errors.ccvv
  ) {
    errors.variant = "success";
    errors.message = "Cartão de crédito é válido";
  }

  return errors;
}
