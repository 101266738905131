import TiposPedido from "../../../util/typesTipoPedido";
import TiposLiquidacao from "../../../util/typesTipoLiquidacao";
import {  Typography } from "@mui/material";


export default function TituloPedidoConcluido({ pedido }) {
 const defineTitulo = () => {
    if (pedido.tipoPedido.id === TiposPedido.Recebimento) {
      return (
        <>
          Estamos processando sua <br />
          ordem de pagamento no valor de:
        </>
      );
    } else if (pedido.tipoPedido.id === TiposPedido.CompraEspecie) {
      return (
        <>
          Estamos processando seu pedido <br />
          Valor final que irá receber é de:
        </>
      );
    } else if ( (
      pedido.tipoPedido.id === TiposPedido.VendaEspecie &&
      pedido.formaPagamento &&
      pedido.formaPagamento.tipoLiquidacao &&
      pedido.formaPagamento.tipoLiquidacao.id === TiposLiquidacao.CartaoCredito
    ) ) {
      return (
        <>
          Pagamento via cartão de teste <br />
          está em processamento:
        </>
      );
    } else {
      return <Typography sx={{ textAlign: 'center' }}>Estamos aguardando o pagamento de:</Typography>;
    }
  };

  return defineTitulo();
}
