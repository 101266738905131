import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// ? ======================== Chamadas Mui =============================

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Skeleton } from "@mui/material";
import { Typography } from "@mui/material";
// ? ======================== Chamadas componentes =============================

import {
  CupomDesconto,
  Timer,
  Titulo,
  LoadingItensCarrinho,
  Subtitulo,
  BadgeItemCarrinho,
} from "../../../../../components";
import RemessaStyle from "./styles.js";
import { formatMoneyInStringForm, handlerCalculaParcela } from "../../../../../util/helpers";
import useAlert from "../../../../../hooks/useAlert";
import { useAuth } from "../../../../../hooks/AuthContext";

import { getItemRecebimento } from "../../../../../api/Carrinho/TranferenciaInternacional";

import ItemCambioCarrinho from "../../../../../components/ItemCambioCarrinho";
import CarrinhoPage from "../../../../../components/CarrinhoPage";
import { useMutation } from "react-query";
import { LimpaCarrinho } from "../../../../../api/PedidoComercial";
import useLojaFechada from "../../../../../hooks/useLojaFechada";

import { finalizar } from "../../../../../api/Carrinho/TranferenciaInternacional";
import { clearLocalStorageTimerCarrinho } from "../../../../../util/LocalStorage";
import typesTipoPedido from "../../../../../util/typesTipoPedido";
import { StepRecebimento } from "../../../../../util/StepsComercial";
import useSeoConfig from "../../../../../hooks/useSeoConfig";

export default function ItemRecebimento() {
  const classes = RemessaStyle();
  const navigation = useNavigate();
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [loadingConfirmePedido, setLoadingConfirmePedido] = useState(false);
  const { sendPageAtual } = useSeoConfig();
  const [itemCarrinhoRecebimento, setItemCarrinhoRecebimento] = useState(null);

  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();



  useEffect(() => {
    sendPageAtual("Confirmacao_items_recebimento")
  }, []);

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const init = async (idCliente, idOrigem) => {
    var response = await getItemRecebimento(idCliente, idOrigem);

    if (!!!response?.data || response?.data?.itens?.length == 0)
      navigation("/CarrinhoVazio", {
        replace: false,
      });

    setItemCarrinhoRecebimento(response.data);
  };

  useEffect(() => {
    init(user.id, user.idOrigem ?? 0);
  }, []);

  const mutationRemoveItem = useMutation(LimpaCarrinho, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoadingConfirmePedido(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");

      clearLocalStorageTimerCarrinho();
      setTimeout(() => {
        navigation("/CarrinhoVazio", {
          replace: false,
        });
      }, 2000);
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R9PKI `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      setTimeout(() => {
        setLoadingConfirmePedido(false);
      }, 2000);
    },
  });

  const handlerRemoveItem = (idMoeda) => (event) => {
    mutationRemoveItem.mutate(user.id);
  };

  const handleNext = async (event) => {
    event.preventDefault();
    try {
      setLoadingConfirmePedido(true);
      const responseFinalizacao = await finalizar(user.id);

      if (!!responseFinalizacao.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${responseFinalizacao.errorMessage} - R6674`
        );

      if (!!!responseFinalizacao.data)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possivel finalizar o pedido - RA942`
        );

      showAlertSuccess(
        "Oba, seu pedido foi registrado, espere um pouco e veja como efetuar o pagamento"
      );

      if (responseFinalizacao && !responseFinalizacao.errorMessage) {
        clearLocalStorageTimerCarrinho();
        setTimeout(() => {
          setLoadingConfirmePedido(false);
          navigation(
            `/Recebimento/PedidoConcluidoPagamentoEscolhido/${responseFinalizacao.data}`
          );
        }, 2500);
      }
    } catch (error) {
      setLoadingConfirmePedido(false);
      showAlertError(`${error} - R8C46`);
    }
  };

  const handleBack = () => {
    navigation("/carrinho/transferencia-internacional/recebimento/pagamento");
  };

  return (
    <>

      <CarrinhoPage
        handleBack={handleBack}
        handleNext={handleNext}
        steps={StepRecebimento}
        activeStep={2}
        loadingButton={loadingConfirmePedido}
      >
        {!!itemCarrinhoRecebimento?.dataExpiracao && (
          <Timer
            active={!!itemCarrinhoRecebimento.dataExpiracao}
            dataExpiracao={itemCarrinhoRecebimento?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}

        {!loadingConfirmePedido && itemCarrinhoRecebimento ? (
          <Grid item className={classes.BlocoConferir}>
            <Titulo className={classes.Titulo}>Confira seu pedido</Titulo>
            {itemCarrinhoRecebimento && (
              <>
                <ItemCambioCarrinho
                  itens={itemCarrinhoRecebimento.itens}
                  impostos={itemCarrinhoRecebimento.impostos}
                  handlerRemoveItem={handlerRemoveItem}
                />
                <Divider sx={{ mt: 1 }} />
                <Grid
                  container
                  sx={{ justifyContent: "space-between", mb: 2, mt: 2 }}
                  xs={12}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Ordem Selecionada:{" "}
                    {itemCarrinhoRecebimento?.ordemPagamento?.codigoOrdem}
                  </Typography>
                </Grid>

                <Divider sx={{ mt: 1 }} />
                <Grid
                  container
                  sx={{ justifyContent: "space-between", mb: 2, mt: 2 }}
                  xs={12}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {itemCarrinhoRecebimento?.natureza?.descricao}
                  </Typography>
                </Grid>
              </>
            )}

            <Divider />
            {itemCarrinhoRecebimento &&
              itemCarrinhoRecebimento.cupom &&
              itemCarrinhoRecebimento.cupom.valorDesconto ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  {" "}
                  <Subtitulo
                    color="text.secondary"
                    label="SubTotal"
                  ></Subtitulo>
                  <Subtitulo sx={{ fontWeight: "500" }} align="right">
                    R${" "}
                    {formatMoneyInStringForm(
                      itemCarrinhoRecebimento.valorTotalMoedaNacional -
                      itemCarrinhoRecebimento.cupom.valorDesconto,
                      2
                    )}
                  </Subtitulo>
                </Box>
              </>
            ) : (
              <Skeleton width={"100%"} height={0} />
            )}
          </Grid>
        ) : (
          <LoadingItensCarrinho />
        )}
        <Box>
          {" "}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <CupomDesconto
              cupom={itemCarrinhoRecebimento && itemCarrinhoRecebimento?.cupom}
              isLoading={loadingConfirmePedido}
              setIsLoading={setLoadingConfirmePedido}
              idTipoPedido={typesTipoPedido.Recebimento}
              idMoeda={
                itemCarrinhoRecebimento &&
                itemCarrinhoRecebimento?.itens[0] &&
                itemCarrinhoRecebimento?.itens[0].moeda.id
              }
              idTipoMercado={1}
              onReflesh={() => init(user.id, user.idOrigem ?? 0)}
            />
          </Grid>
        </Box>

        {itemCarrinhoRecebimento &&
          itemCarrinhoRecebimento?.valorTotalMoedaNacional &&
          (!loadingConfirmePedido ? (
            <>
              {itemCarrinhoRecebimento.quantidadeParcelas > 0 &&
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Subtitulo sx={{ fontWeight: "normal" }}>
                    {itemCarrinhoRecebimento.quantidadeParcelas}x de R$ {formatMoneyInStringForm(handlerCalculaParcela(itemCarrinhoRecebimento))}
                  </Subtitulo>
                </Box>
              }
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 0.8,
                  mb: 4,
                }}
              >
                <Titulo
                  sx={{ fontSize: 20, fontWeight: "bold" }}
                  label="Total"
                ></Titulo>

                <BadgeItemCarrinho carrinho={itemCarrinhoRecebimento}>
                  <Titulo
                    sx={{
                      fontWeight: "bold",
                      textAlign: "end",
                      fontSize: 20,
                      mt: 0.8,
                      mb: 4,
                    }}
                    color="primary"
                    align="right"
                  >
                    R${" "}
                    {formatMoneyInStringForm(
                      itemCarrinhoRecebimento?.valorTotalMoedaNacional,
                      2
                    )}
                  </Titulo>
                </BadgeItemCarrinho>
              </Box>
            </>

          ) : (
            <Skeleton width={"100%"} height={50} animation="wave" />
          ))}
      </CarrinhoPage>
    </>
  );
}
