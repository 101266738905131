import FilePresentIcon from "@mui/icons-material/FilePresent";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Subtitulo, Titulo } from "../";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";
import { GetCanalBancarioExteriorByIDMoeda } from "../../api/FormaPagamento";
import { useAuth } from "../../hooks/AuthContext";
import { formatMoneyInString } from "../../util/helpers";
import {
  CANALBANCARIOEXTERIOR,
  PEDIDOCOMPROVANTES,
} from "../../util/typesReactQuery";
import TiposPedido from "../../util/typesTipoPedido";
import AnexarComprovante from "../AnexarComprovante";
import OperacaoBancarioRecebimento from "./OperacaoBancarioRecebimento";
import OperacoesBancarias from "./OperacoesBancarias";
import Pix from "./Pix";
import TituloPedidoConcluido from "./TituloPedidoConcluido";
import useStyle from "./style";
import { ButtonCustom } from "../Botoes/Button";
import { useNavigate } from "react-router-dom";
import TiposStatusPedido from "../../util/typesTipoStatusPedido";
import TiposLiquidacao from "../../util/typesTipoLiquidacao";

const PedidoConcluidoPagamentoEscolhido = ({
  pedido,
  setErrorMessage,
  margin,
  isTodosDetalhes = true,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const navigation = useNavigate();
  const { user } = useAuth();
  const [isRecebimento, setIsRecebimento] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState([
    { ...pedido.formaPagamento },
  ]);

  const { data: comprovantes, refetch: refetchComprovantes } = useQuery(
    PEDIDOCOMPROVANTES,
    () => getComprovantePedidoByIDPedido(user?.id, pedido?.id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch } = useQuery(
    CANALBANCARIOEXTERIOR,
    () => GetCanalBancarioExteriorByIDMoeda(pedido?.itens[0]?.moeda?.id),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage)
          setErrorMessage(
            `Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum canal exterior - RAF4E `
          );

        setFormaPagamento(data.data);
      },
      onError: (error) => {
        setErrorMessage(
          `Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R34C8 `
        );
      },
      enabled: false,
    }
  );

  useEffect(() => {
    refetchComprovantes();
    if (pedido?.tipoPedido?.id === TiposPedido.Recebimento) {
      setIsRecebimento(true);
      refetch();
      return;
    }
  }, [pedido]);

  const handlerMeusPedidos = () => {
    navigation("/MeusPedidos");
  };

  return (
    <>
      <Grid
        container
        className={classes.Container}
        justifyContent="center"
        alignItems="baseline"
      >
        {isTodosDetalhes && (
          <Grid item lg={12} md={6} sm={12}>
            <Typography
              variant="h5"
              sx={{ mb: 1, mt: 1, fontWeight: "bold", textAlign: "center" }}
            >
              Recebemos seu pedido
            </Typography>
            <TituloPedidoConcluido pedido={pedido} />

            <Titulo
              color={"primary"}
              sx={{
                fontWeight: "bold",
                fontSize: 28,
                textAlign: "center",
                mb: 2,
              }}
            >
              {formatMoneyInString(
                pedido?.valorTotalMoedaNacional || pedido?.valorTotal
              )}
            </Titulo>

            {isRecebimento && (
              <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} />
                Numero da Ordem: {pedido?.ordemPagamento?.codigoOrdem}
              </Subtitulo>
            )}

            <Subtitulo
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 1,
                justifyContent: "center",
                mb: 2,
              }}
            >
              <InfoIcon color="primary" sx={{ mr: theme.spacing(1) }} />
              Você receberá atualizações do seu pedido por e-mail e pela
              plataforma
            </Subtitulo>

            {pedido?.chipInternacional?.length > 0 && (
              <>
                <Subtitulo
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 1,
                    justifyContent: "center",
                  }}
                >
                  <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} />
                  Problemas com o Chip, entre em contato pelo site:
                  <a
                    href="https://ezsimconnect.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginLeft: ".2rem" }}
                  >
                    https://ezsimconnect.com/
                  </a>
                </Subtitulo>

                <Subtitulo
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 1,
                    justifyContent: "center",
                  }}
                >
                  <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} />
                  Para ativar o seu chip, acesse a página de
                  <ButtonCustom onClick={handlerMeusPedidos} variant="text">
                    "Meus Pedidos".
                  </ButtonCustom>
                </Subtitulo>
              </>
            )}
          </Grid>
        )}

        <Grid item md={isTodosDetalhes ? 4 : 12} lg={12} sm={8}>
          {pedido?.formaPagamento?.tipoLiquidacao?.textId === "PIX" ? (
            <Pix margin={margin} pedido={pedido} />
          ) : isRecebimento ? (
            <OperacaoBancarioRecebimento
              dadosBancarios={pedido?.clienteDadosBancarios}
            />
          ) : (
            <>
              <OperacoesBancarias
                formaPagamento={formaPagamento}
                isTodosDetalhes={isTodosDetalhes}
              />

              {pedido?.tipoPedido?.id === TiposPedido.CompraEspecie && (
                <OperacaoBancarioRecebimento
                  dadosBancarios={pedido?.clienteDadosBancarios}
                />
              )}
            </>
          )}
        </Grid>

        

        {pedido?.formaPagamento?.tipoLiquidacao.textId !== "PIX" &&
        pedido?.formaPagamento?.tipoLiquidacao?.id !== TiposLiquidacao.CartaoCredito &&
          pedido?.tipoPedido?.id !== TiposPedido.CompraEspecie &&
          pedido?.tipoPedido?.id !== TiposPedido.SeguroViagem &&
          pedido?.tipoPedido?.id !== TiposPedido.Recebimento &&
          (
            <div className={classes.divAnexo}>
              <hr style={{ marginBottom: "1rem" }} />
              <AnexarComprovante
                loading={false}
                documentos={comprovantes}
                idPedido={pedido?.id}
                userID={user?.id}
                permiteUpload={pedido?.status && pedido?.status?.id === TiposStatusPedido.Pendente}
              />
            </div>
          )}
      </Grid>
    </>
  );
};

export default PedidoConcluidoPagamentoEscolhido;
