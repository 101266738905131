import React, { useEffect, useState } from "react";
import { AppRoutes } from "./Routes";
import { AuthProvider } from "./hooks/AuthContext";
import { QueryClientProvider, QueryClient } from "react-query";
import { Feedback } from "./components/Feedback";
import { Toaster } from "sonner";
import { useMediaQuery } from "@mui/material";
import { getScripts } from "./api/ScriptsHeader";

const client = new QueryClient();
process.env.REACT_QUERY_DEVTOOLS_HIDE_QUERY_NAMES = "true";
function App() {
  const [scripts, setScripts] = useState([]);
  const isTelaPequena = useMediaQuery("(max-width:728px)");

  useEffect(() => {
    const loadScripts = async () => {
      try {
        const { status, data, errorMessage } = await getScripts();
        if (status === 200) {
          setScripts(data); // Armazena os scripts recebidos
        } else {
          console.error("Erro ao carregar os scripts: ", errorMessage);
        }
      } catch (error) {
        console.error("Erro ao carregar os scripts: ", error);
      }
    };

    loadScripts();
  }, []);

  useEffect(() => {
    if (scripts.length > 0) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = scripts; // Adiciona o HTML retornado pela API

      const scriptTags = tempDiv.getElementsByTagName("script");
      for (let scriptTag of scriptTags) {
        const newScript = document.createElement("script");
        newScript.src = scriptTag.src; // Usa o src dos scripts recebidos
        newScript.async = true; // Define async para carregamento assíncrono
        document.body.appendChild(newScript); // Insere o script no body
      }
    }
  }, [scripts]);
  
  return (
    <>
      {/* Usa dangerouslySetInnerHTML para injetar o script no DOM */}
      {/* <div dangerouslySetInnerHTML={{ __html: scripts }} /> */}
      <QueryClientProvider client={client}>
        <AuthProvider>
          <Feedback />

          <Toaster
            richColors
            position="top-center"
            visibleToasts={3}
            toastOptions={{
              style: {
                width: isTelaPequena ? "90%" : "125%",
              },
            }}
          />
          <AppRoutes />
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
